@import 'base';
html,
body {
    @include full-size;
}
body {
    background: url($background-image) no-repeat 50% 50% fixed;
    text-align: center;
    font-size: $base-font-size;
    font-family: $body-font-family, sans-serif;

    @include max-screen($breakpoint-mobile) {
        background-image: url($background-image-mobile);
    }
    @include prefixer(background-size, cover, webkit moz o spec);
    * {
        box-sizing: border-box;
    }
}
h1,
h2,
h3 {
    font-weight: normal;
    font-size: em(14px);
}
a {
    color: white;
    text-decoration: none;
    &:hover {
        opacity: 0.6;
    }
}
.uiux-video-background {
    position: relative;
    display: block;
    opacity: 0;
    -webkit-transition-duration: 2000ms;
    transition-duration: 2000ms;
    -webkit-transition-property: opacity;
    transition-property: opacity;

    @include max-screen($breakpoint-mobile) {
        display: none;
    }
    -webkit-backface-visibility: hidden;
    .video-background-wrapper,
    .video-background-mask {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        overflow: hidden;
        min-width: 100%;
        min-height: 100%;

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 0;
            overflow: hidden;
            width: 100vw;
            height: 100vh;
            opacity: 1;
        }
    }
    .video-background-mask {
        background: url($background-overlay-pattern);
    }
}
.uiux-content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    @include full-size;
    align-items: center;
    justify-content: center;
    .uiux-block {
        width: 100%;
    }
    .uiux-header {
        padding: em(40px);

        flex: 1 1;
        @include max-screen($breakpoint-tablet) {
            flex: 1 1 60%;
        }
        @include min-screen($breakpoint-tablet) {
            padding: 0;

            @include vertical-align;
        }
        @include min-screen($breakpoint-desktop) {

            h1,
            h2 {
                font-size: $base-font-size+1;
            }
        }
        h1,
        h2 {
            margin: 0 auto;
            line-height: 1.6em;
            .uiux-header-name {
                display: block;
                color: #e4e2df;
                text-transform: uppercase;
                text-shadow: 0 4px 0 $shadow-color;
                font-size: em(65px);
                font-family: $header-font-family, sans-serif;
                line-height: 1.6;

                @include max-screen($breakpoint-mobile) {
                    text-shadow: 0 2px 0 $shadow-color;
                    font-size: em(50px);
                    line-height: 2em;
                }
                @include min-screen($breakpoint-tablet) {
                    font-size: em(72px);
                }
                a {
                    border-bottom: 1px dotted white;
                }
            }
        }
        h2 {
            max-width: em(480px);
        }
        .avatar {
            overflow: hidden;
            margin: 0 auto em(15px);
            width: em(140px);
            height: em(136px);
            border-radius: 50%;
            background: url($avatar-image) no-repeat;

            @include max-screen-height($breakpoint-short) {
                display: none;
            }
            @include prefixer(transition, all 0.5s ease-in-out, webkit spec);
            @include prefixer(box-shadow, 0px 4px 0px 0px $shadow-color, webkit moz o spec);
            @include prefixer(background-size, cover, webkit moz o spec);
            @include max-screen($breakpoint-tablet) {
                width: em(110px);
                height: em(106px);
            }
        }
    }
    .uiux-nav {
        flex: 0 0 150px;
        @include max-screen($breakpoint-tablet) {
            flex: 0 0 40%;
        }
        @include max-screen-height($breakpoint-short) {
            .uiux-nav-header {
                display: none;
            }
        }
        .uiux-nav-buttons {
            margin: auto;
            max-width: em(1100px);
        }
        .uiux-nav-buttons,
        .uiux-nav-button {
            padding: 0;
            list-style-type: none;
        }
        .uiux-nav-button {
            display: block;
            float: left;
            margin: 0;
            width: 20%;

            @include max-screen($breakpoint-tablet) {
                margin-bottom: 6%;
                width: 50%;
                &:nth-last-child(1) {
                    padding: 0 5%;
                    width: 100%;
                    a {
                        width: 100%;
                    }
                }
            }
            a {
                position: relative;
                display: block;
                overflow: hidden;
                margin: 0 auto;
                width: 80%;
                height: 2.3em;
                border: 3px solid white;
                border-radius: 3px;
                background: rgba(255, 255, 255, 0.2);
                line-height: 2em;

                -webkit-backface-visibility: hidden;
                -moz-osx-font-smoothing: grayscale;
                > span {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    font-size: 0.9em;

                    @include full-size;
                    @include prefixer(transition, transform 0.3s, webkit spec);
                    @include prefixer(transition-timing-function, cubic-bezier(0.2, 1, 0.3, 1), webkit spec);
                }
                &:focus {
                    outline: none;
                }
                .button-label {
                    @include prefixer(transform, translate3d(0, 0%, 0), webkit spec);
                }
                .hover-button-label {
                    @include prefixer(transform, translate3d(0, 100%, 0), webkit spec);
                }
                &:hover {
                    background: rgba(255, 255, 255, 1);
                    color: #444;
                    .button-label {
                        @include prefixer(transform, translate3d(0, -100%, 0), webkit spec);
                    }
                    .hover-button-label {
                        @include prefixer(transform, translate3d(0, 0%, 0), webkit spec);
                    }
                }
            }
        }
    }
}
